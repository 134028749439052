import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutListItem,
} from "@volley/data";
import type { AppParameters } from "@volley/shared/app-models";
import type {
    ResponsiveState,
    ResponsiveParameters,
    DifficultyLevel,
} from "@volley/shared/apps/responsive-models";

import logger from "../../../../../log";
import { logFetchError } from "../../../../../util/fetchApi";
import useDialog from "../../../../Dialog/useDialog";
import ResizableWorkoutVisualizer from "../../../../common/Visualizer/ResizableWorkoutVisualizer";
import {
    VisualizerTrainer,
    WorkoutForVisualizer,
} from "../../../../common/Visualizer/types";
import { useSelectedSport } from "../../../../common/context/sport";
import { useStatus } from "../../../../hooks/status";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import CaptureToast from "../../Shared/CaptureToast";
import PlayAppBar from "../../Shared/PlayAppBar";
import SpeedAdjustment from "../../Shared/SpeedAdjustment";
import ThrowCount from "../../Shared/ThrowCount";
import useAppWorkouts from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import LocalizingDialog from "../10-serve-and-volley/LocalizingDialog";

import WorkoutPicker from "./WorkoutPicker";

type ParamsAction =
    | { type: "shots"; value: number }
    | { type: "speedAdjustment"; value?: number }
    | { type: "mode"; value: "follow" | "probability" }
    | { type: "difficulty"; value: DifficultyLevel };

function paramsReducer(
    state: ResponsiveParameters,
    action: ParamsAction,
): ResponsiveParameters {
    switch (action.type) {
        case "shots":
            return { ...state, numberOfBalls: action.value };
        case "speedAdjustment":
            return { ...state, speedAdjustment: action.value };
        case "difficulty":
            return { ...state, difficulty: action.value };
        case "mode":
            return { ...state, mode: action.value, difficulty: "hard" };
        default:
            throw new Error(
                "Invalid action to perform on a Responsive Parameters set.",
            );
    }
}

const DEFAULT_PARAMETERS: ResponsiveParameters = {
    numberOfBalls: 60,
    initialDelay: 5,
    difficulty: "hard",
    mode: "probability",
};

export default function ResponsivePlayApp(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const idMaybe = React.useMemo(() => parseInt(id ?? "", 10), [id]);
    const navigate = useNavigate();
    const [paramsDirty, setParamsDirty] = React.useState(false);
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        DEFAULT_PARAMETERS,
    );
    const [loading, setLoading] = React.useState(false);
    const parameters = paramsState as unknown as AppParameters;
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const [playClicked, setPlayClicked] = React.useState<boolean>(false);
    const [usePlannedPosition, setUsePlannedPosition] = React.useState(false);
    const { selected: selectedSport } = useSelectedSport();
    const { position, isVisionFaulted, isVisionStarting, cancel } =
        usePosition();
    const { status } = useStatus();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    const [availableWorkouts, setAvailableWorkouts] = React.useState<
        AppWorkoutListItem[] | null
    >(null);

    const { setDialogType } = useDialog();

    const { loadResponsiveWorkoutList, loadResponsiveWorkoutApp } =
        useAppWorkouts();

    const { stop: stopLift } = useLift();

    const {
        start,
        pause,
        stop: stopWorkout,
        captureDisabled,
        requestError,
        playInitiated,
        playDisabled,
        pauseDisabled,
        playState,
        workoutState,
        captureVideo,
        captureStatus,
    } = useAppWorkoutPlay({
        workout,
        parameters,
        localizedPosition: usePlannedPosition ? undefined : position,
    });

    React.useEffect(() => {
        setLoading(true);
        loadResponsiveWorkoutList()
            .then((results) => {
                setAvailableWorkouts(results);
                return results;
            })
            .catch((e) =>
                logFetchError(
                    e,
                    "Failed to fetch list of responsive workouts.",
                ),
            )
            .finally(() => setLoading(false));
    }, [loadResponsiveWorkoutList]);

    React.useEffect(() => {
        if (idMaybe) {
            setLoading(true);
            loadResponsiveWorkoutApp(idMaybe)
                .then((result) => {
                    setWorkout(result);
                    setParamsDirty(false);
                    stopWorkout().catch(logFetchError);
                })
                .catch((e) => {
                    logFetchError(e, `Failed to load workout ${idMaybe}`);
                })
                .finally(() => setLoading(false));
        }
    }, [idMaybe, loadResponsiveWorkoutApp, stopWorkout]);

    const responsiveWorkoutState =
        workoutState as unknown as ResponsiveState | null;
    const ballsThrown = responsiveWorkoutState?.currentShotNumber ?? 0;
    const totalBalls =
        (parameters as unknown as ResponsiveParameters).numberOfBalls ?? 0;
    const summaryText = playInitiated ? `${ballsThrown} of ${totalBalls}` : "";

    const handlePlayClicked = React.useCallback(async () => {
        if (paramsDirty && playState !== "stopped")
            stopWorkout().catch(logFetchError);
        if (workout && ["TENNIS", "PLATFORM_TENNIS"].includes(selectedSport)) {
            setLocalizingDialogOpen(true);
        } else {
            start();
        }
    }, [workout, selectedSport, start, paramsDirty, stopWorkout, playState]);

    const handleStopWorkout = React.useCallback(async () => {
        await stopWorkout();
    }, [stopWorkout]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        if (playState !== "stopped" || playInitiated) {
            await handleStopWorkout();
        }
    }, [handleStopWorkout, playInitiated, playState, stopLift]);

    const [localizingDialogOpen, setLocalizingDialogOpen] =
        React.useState(false);
    const forceLocalizationRef = React.useRef(false);

    const levelOptions = [
        { value: "easy", label: "Easy" },
        { value: "medium", label: "Medium" },
        { value: "hard", label: "Difficult" },
    ];

    const plannedTrainerPosition: VisualizerTrainer | undefined =
        React.useMemo(() => {
            if (!workout) return undefined;
            return {
                x: workout.positionX,
                y: workout.positionY,
                yaw: workout.positionYaw,
                heightIn: workout.positionHeight,
            };
        }, [workout]);

    const workoutForVisualizer: WorkoutForVisualizer | undefined =
        React.useMemo(
            () =>
                localizingDialogOpen || !plannedTrainerPosition
                    ? undefined
                    : {
                          trainer: plannedTrainerPosition,
                          shots: [],
                          AOIs: [],
                          player: [],
                      },
            [localizingDialogOpen, plannedTrainerPosition],
        );

    const workoutForLocalizingDialog: WorkoutForVisualizer = React.useMemo(
        () => ({
            trainer: plannedTrainerPosition!,
            localized: position && {
                ...position,
                heightIn: plannedTrainerPosition?.heightIn || 0,
            },
            player: [],
            shots: [],
            AOIs: [],
        }),
        [plannedTrainerPosition, position],
    );

    React.useEffect(() => {
        if (playClicked && workout) {
            setPlayClicked(false);
            start();
        }
    }, [playClicked, workout, start]);

    const onChangeSpeedAdjustment = React.useCallback((value?: number) => {
        paramsDispatch({
            type: "speedAdjustment",
            value,
        });
        setParamsDirty(true);
    }, []);

    return (
        <Stack
            sx={{
                backgroundColor: "background.default",
                mx: -1,
                p: 2,
                height: "90vh",
                pb: 16,
            }}
        >
            {plannedTrainerPosition && workoutForLocalizingDialog && (
                <LocalizingDialog
                    dialogOpen={localizingDialogOpen}
                    onLocalized={(result) => {
                        logger.info(`[responsive play] - Localized: ${result}`);
                        setLocalizingDialogOpen(false);
                        if (result === "good") {
                            forceLocalizationRef.current = false;
                            setPlayClicked(true);
                        } else {
                            forceLocalizationRef.current = true;
                        }
                    }}
                    onCanceled={() => {
                        logger.info(
                            `[responsive play] - Localization canceled by user`,
                        );
                        cancel();
                        setLocalizingDialogOpen(false);
                    }}
                    onUsePlanned={() => {
                        logger.info(
                            "[responsive play] - User chose to use planned position starting workout immediately",
                        );
                        setLocalizingDialogOpen(false);
                        forceLocalizationRef.current = false;
                        setUsePlannedPosition(true);
                        setPlayClicked(true);
                    }}
                    plannedPosition={plannedTrainerPosition}
                    force={forceLocalizationRef.current}
                    workout={workoutForLocalizingDialog}
                />
            )}
            <LiftModal stop={handleLiftStop} />
            {requestError && (
                <Typography color="error.main">{requestError}</Typography>
            )}
            {visionUnavailable && (
                <Typography color="info.main" variant="caption">
                    Play will be enabled when paired and cameras fully
                    initialized
                </Typography>
            )}
            <Typography variant="h3" color="primary.main">
                Responsive Play
            </Typography>
            <Stack spacing={2}>
                <WorkoutPicker
                    fullWidth
                    loading={loading}
                    disabled={playState === "playing"}
                    options={availableWorkouts ?? []}
                    selected={workout?.id ?? null}
                    onChange={(id) =>
                        navigate(`/content/apps/workouts/plugin/play/2/${id}`, {
                            replace: true,
                        })
                    }
                />
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography
                        variant="caption"
                        sx={{
                            minWidth: "30px",
                        }}
                    >
                        Difficulty
                    </Typography>
                    <ButtonGroup
                        disabled={playState === "playing"}
                        fullWidth
                        size="small"
                        color="info"
                    >
                        {levelOptions.map((o) => (
                            <Button
                                key={o.value}
                                variant={
                                    paramsState.difficulty === o.value
                                        ? "contained"
                                        : "outlined"
                                }
                                size="small"
                                sx={{
                                    padding: "2px",
                                    color:
                                        paramsState.difficulty === o.value
                                            ? "common.white"
                                            : "primary.light",
                                    backgroundColor:
                                        paramsState.difficulty === o.value
                                            ? "primary.light"
                                            : "common.white",
                                    fontSize: "10px",
                                }}
                                onClick={() => {
                                    paramsDispatch({
                                        type: "difficulty",
                                        value: o.value as DifficultyLevel,
                                    });
                                    setParamsDirty(true);
                                }}
                            >
                                {o.label}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Stack>
                <ThrowCount
                    disabled={playState === "playing"}
                    selectedThrowCount={paramsState.numberOfBalls}
                    onUserThrowCountChanged={(count) => {
                        paramsDispatch({ type: "shots", value: count });
                        setParamsDirty(true);
                    }}
                />
                {workout && (
                    <SpeedAdjustment
                        disabled={playState === "playing"}
                        value={paramsState.speedAdjustment}
                        sport={selectedSport}
                        cacheKey="responsive-play"
                        onChange={onChangeSpeedAdjustment}
                    />
                )}
                {status && isVisionFaulted && (
                    <Button
                        variant="text"
                        startIcon={<ErrorIcon />}
                        onClick={() =>
                            setDialogType("VisionFaultServeAndVolleyDialog")
                        }
                        color="error"
                    >
                        <Typography variant="h4">
                            Camera system unavailable
                        </Typography>
                    </Button>
                )}
                {workoutForVisualizer && plannedTrainerPosition && (
                    <ResizableWorkoutVisualizer
                        workout={workoutForVisualizer}
                        positionProximity="Unavailable"
                        maxHeight={225}
                    />
                )}
            </Stack>

            <PlayAppBar
                onPauseClicked={() => pause()}
                onPlayClicked={() => handlePlayClicked()}
                pauseDisabled={pauseDisabled}
                playDisabled={
                    visionUnavailable || playDisabled || workout === null
                }
                playState={playState}
                showRecord
                onRecordClicked={() => captureVideo()}
                playSummary={summaryText}
                recordDisabled={visionUnavailable || captureDisabled}
                recordingStatus={captureStatus}
            />

            <CaptureToast captureStatus={captureStatus} />
        </Stack>
    );
}
