import * as React from "react";

import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TuneIcon from "@mui/icons-material/Tune";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
    SingleShotConfig,
    SingleShotParameters,
} from "@volley/shared/apps/single-shot-models";

import { CoordSys } from "../../../../../util/position-types";
import { Sport, useSelectedSport } from "../../../../common/context/sport";
import { SMALLE_MAX_BALLS } from "../../Accordions/ParamsAccordion";
import SideSelector from "../../Shared/SideSelector";
import SpeedAdjustment from "../../Shared/SpeedAdjustment";
import ThrowCount from "../../Shared/ThrowCount";
import ThrowInterval from "../../Shared/ThrowInterval";
import { updateLocalParams } from "../../localWorkoutState";

import SingleShotDuplicateButton from "./SingleShotDuplicateButton";
import { ParameterAction } from "./parametersReducer";
import type { WorkoutAction, WorkoutForm } from "./reducer";

interface Props {
    workout: WorkoutForm;
    parameters: SingleShotParameters;
    parametersDispatch: React.Dispatch<
        ParameterAction<keyof SingleShotParameters>
    >;
    workoutDispatch: React.Dispatch<WorkoutAction>;
    onDelete: () => void;
    setMode: (mode: "play" | "edit") => void;
    saveWorkout: (
        asCopy: boolean,
        name?: string,
        description?: string,
    ) => Promise<void>;
    advancedExpanded: boolean;
    setAdvancedExpanded: (advancedExpanded: boolean) => void;
    resetToDefault: () => Promise<void>;
    resetToDefaultDisabled?: boolean;
    disabled?: boolean;
}

export default function PlayControls({
    workout,
    parameters,
    parametersDispatch,
    workoutDispatch,
    onDelete,
    setMode,
    saveWorkout,
    advancedExpanded,
    setAdvancedExpanded,
    resetToDefault,
    resetToDefaultDisabled = false,
    disabled = false,
}: Props): JSX.Element {
    const { selected: selectedSport } = useSelectedSport();

    const onParameterChange = React.useCallback(
        <K extends keyof SingleShotParameters>(action: ParameterAction<K>) => {
            updateLocalParams(
                { [action.type]: action.value },
                workout.appId,
                workout.id,
            );
            parametersDispatch(action);
            workoutDispatch({ type: "setDirty", value: true });
        },
        [parametersDispatch, workoutDispatch, workout.appId, workout.id],
    );

    const playerPositionForSide = React.useMemo(() => {
        if (workout) {
            const { playerPosition } =
                workout.config as unknown as SingleShotConfig;
            return {
                x: playerPosition?.x ?? 0,
                y: playerPosition?.y ?? 0,
                sys: (selectedSport === "PLATFORM_TENNIS"
                    ? "court"
                    : "physics") as CoordSys,
            };
        }

        return {
            x: 0,
            y: 0,
            sys: "physics" as CoordSys,
        };
    }, [workout, selectedSport]);

    const onChangeSpeedAdjustment = React.useCallback(
        (value?: number) => {
            onParameterChange({ type: "speedAdjustment", value });
        },
        [onParameterChange],
    );

    return (
        <>
            {advancedExpanded && (
                <Stack direction="row" justifyContent="space-around">
                    <Button
                        startIcon={<EditIcon />}
                        onClick={() => setMode("edit")}
                    >
                        Edit
                    </Button>
                    {workout.isOwner ? (
                        <SingleShotDuplicateButton
                            defaultName={workout.name}
                            disabled={!workout.isDirty}
                            onConfirm={async (name, description) => {
                                const asCopy =
                                    name !== workout.name || workout.id === 0;
                                await saveWorkout(asCopy, name, description);
                            }}
                            title={workout.id === 0 ? "New" : "Save"}
                        />
                    ) : (
                        <SingleShotDuplicateButton
                            defaultName={`Copy of ${workout.name}`}
                            onConfirm={async (name, description) => {
                                const asCopy = true;
                                await saveWorkout(asCopy, name, description);
                            }}
                            title={workout.isDirty ? "Save As" : "Duplicate"}
                        />
                    )}
                    <Button
                        size="small"
                        startIcon={<CachedIcon />}
                        onClick={() => resetToDefault()}
                        disabled={resetToDefaultDisabled}
                    >
                        Reset
                    </Button>
                    {workout.isOwner && (
                        <Button startIcon={<DeleteIcon />} onClick={onDelete}>
                            Delete
                        </Button>
                    )}
                </Stack>
            )}
            <SideSelector
                disabled={disabled}
                playerPosition={playerPositionForSide}
                playMode={parameters.playMode}
                setPlayMode={(value) =>
                    onParameterChange({ type: "playMode", value })
                }
                workoutX={workout.positionX}
            />
            {!advancedExpanded && (
                <Stack>
                    <Typography
                        variant="caption"
                        textAlign="center"
                        width="100%"
                        component="div"
                    >
                        {`${parameters.intervalOverride} sec. rate, `}
                        {`${parameters.numberOfBalls === SMALLE_MAX_BALLS ? "all" : parameters.numberOfBalls} balls`}
                    </Typography>
                    <Button
                        fullWidth
                        size="small"
                        endIcon={<TuneIcon />}
                        disabled={disabled}
                        onClick={() => setAdvancedExpanded(true)}
                    >
                        Advanced
                    </Button>
                </Stack>
            )}
            <Collapse in={advancedExpanded}>
                <Stack spacing={2}>
                    <ThrowInterval
                        disabled={disabled}
                        selectedThrowInterval={parameters.intervalOverride}
                        onUserThrowIntervalChanged={(value) =>
                            onParameterChange({
                                type: "intervalOverride",
                                value,
                            })
                        }
                    />
                    <ThrowCount
                        disabled={disabled}
                        selectedThrowCount={parameters.numberOfBalls}
                        onUserThrowCountChanged={(value) =>
                            onParameterChange({ type: "numberOfBalls", value })
                        }
                    />
                    <SpeedAdjustment
                        cacheKey={workout.id.toString()}
                        sport={workout.sport.name as Sport}
                        onChange={onChangeSpeedAdjustment}
                        value={parameters.speedAdjustment}
                        disabled={disabled}
                    />
                </Stack>
            </Collapse>
        </>
    );
}
