import * as React from "react";

import AbcIcon from "@mui/icons-material/Abc";
import AdjustIcon from "@mui/icons-material/Adjust";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import ListIcon from "@mui/icons-material/List";
import PublishIcon from "@mui/icons-material/Publish";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";

import type { ModuleConfig } from "@volley/shared/apps/module-models";

import { useCurrentUser } from "../../../../../../hooks/currentUser";
import PublishSelect from "../../../../ContentManagement/PublishSelect";
import Sets from "../../../../Shared/Sets";
import Tagging from "../../../../Shared/Tagging";
import ThrowCount from "../../../../Shared/ThrowCount";
import ThrowInterval from "../../../../Shared/ThrowInterval";
import ThrowIntervalAfterSet from "../../../../Shared/ThrowIntervalAfterSet";
import { WorkoutAction, WorkoutForm } from "../reducer";

const THROW_MARKS = [
    { value: 1, label: "1" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
];

type TabValue =
    | "name"
    | "instructions"
    | "defaults"
    | "contentProvider"
    | "tags";

interface Props {
    workout: WorkoutForm;
    dispatch: React.Dispatch<WorkoutAction>;
}

export default function WorkoutControls({
    workout,
    dispatch,
}: Props): JSX.Element {
    const { currentUser } = useCurrentUser();
    const [tab, setTab] = React.useState<TabValue>("name");
    const config = workout.config as unknown as ModuleConfig;

    return (
        <>
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={(_e, value: TabValue) => setTab(value)}
                sx={{
                    "& .MuiTab-root": {
                        fontSize: 10,
                        minHeight: 24,
                        p: 0,
                        pb: 1,
                    },
                    minHeight: 24,
                }}
            >
                <Tab label="Name" value="name" icon={<AbcIcon />} />
                <Tab
                    label="Instructions"
                    value="instructions"
                    icon={<ListIcon />}
                />
                <Tab label="Defaults" value="defaults" icon={<AdjustIcon />} />
                <Tab label="Tags" value="tags" icon={<BookmarksIcon />} />
                {currentUser?.contentProviderPublishers.length &&
                    workout.isOwner && (
                        <Tab
                            label="Publisher"
                            value="contentProvider"
                            icon={<PublishIcon />}
                        />
                    )}
            </Tabs>
            {!!tab && <Box component="div" pt={2} />}
            {tab === "name" && (
                <Stack spacing={2}>
                    <TextField
                        label="Name"
                        name="name"
                        value={workout.name}
                        required
                        onChange={(e) =>
                            dispatch({
                                type: "name",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <TextField
                        label="Short Description"
                        multiline
                        name="description"
                        value={workout.description}
                        required
                        onChange={(e) =>
                            dispatch({
                                type: "description",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <TextField
                        label="Video Slug"
                        name="videoSlug"
                        value={config.videoSlug}
                        required
                        onChange={(e) =>
                            dispatch({
                                type: "updateConfig",
                                value: {
                                    ...config,
                                    videoSlug: e.currentTarget.value,
                                },
                            })
                        }
                    />
                </Stack>
            )}
            {tab === "instructions" && (
                <TextField
                    label="Instructions"
                    multiline
                    rows={10}
                    name="details"
                    value={config.details}
                    onChange={(e) =>
                        dispatch({
                            type: "updateConfig",
                            value: {
                                ...config,
                                details: e.currentTarget.value,
                            },
                        })
                    }
                />
            )}
            {tab === "defaults" && (
                <Stack spacing={2}>
                    <ThrowCount
                        label="Shots per Set"
                        selectedThrowCount={config.numberOfBalls}
                        onUserThrowCountChanged={(numberOfBalls) =>
                            dispatch({
                                type: "updateConfig",
                                value: { ...config, numberOfBalls },
                            })
                        }
                        marks={THROW_MARKS}
                        min={1}
                        max={30}
                        disabled={false}
                    />
                    <Sets
                        sets={config.sets}
                        setSets={(sets) =>
                            dispatch({
                                type: "updateConfig",
                                value: { ...config, sets },
                            })
                        }
                    />
                    <ThrowInterval
                        selectedThrowInterval={config.interval}
                        onUserThrowIntervalChanged={(interval) =>
                            dispatch({
                                type: "updateConfig",
                                value: { ...config, interval },
                            })
                        }
                        disabled={false}
                    />
                    <ThrowIntervalAfterSet
                        intervalAfterSet={config.intervalAfterSet}
                        setIntervalAfterSet={(intervalAfterSet) =>
                            dispatch({
                                type: "updateConfig",
                                value: { ...config, intervalAfterSet },
                            })
                        }
                    />
                </Stack>
            )}
            {tab === "contentProvider" && (
                <PublishSelect
                    contentProviderId={workout.contentProviderId}
                    setContentProviderId={(value) =>
                        dispatch({ type: "contentProviderId", value })
                    }
                />
            )}
            {tab === "tags" && (
                <Tagging
                    open
                    onCancel={() => setTab("name")}
                    onFinish={(updated) => {
                        setTab("name");
                        dispatch({ type: "tags", value: updated });
                    }}
                    selectedTags={workout.tags ?? {}}
                />
            )}
        </>
    );
}
