import * as React from "react";
import { useLocation } from "react-router-dom";

import type { CoachStatus } from "@volley/shared/coach-models";

import logger from "../../log";
import {
    pairedFetchApi,
    logFetchError,
    FetchError,
    PairRequiredError,
} from "../../util/fetchApi";
import { cancelRetry, registerRetry, retry } from "../../util/retry";
import { checkForSession } from "../Sessions/util";
import Loading from "../common/Loading";

import { useCurrentUser } from "./currentUser";
import { usePairingContext } from "./pairingStatus";
import useInterval from "./useInterval";

export const RAPID_POLL = 1000;

export type NetworkStateCode =
    | "Connected"
    | "Connecting"
    | "ConnectionReestablishing"
    | "NotAuthorized"
    | "ServiceConnectionDown"
    | "TrainerConnectionDown"
    | "TrainerOffline"
    | "Unknown";

export interface StatusValue {
    status: CoachStatus | null;
    networkStateCode: NetworkStateCode;
    error: StatusError | null;
    hasFault: boolean;
    removeRapidPollCheck: (key: string) => void;
    startRapidPoll: (
        newInterval: number,
        predicate?: FastPollingPredicate,
        predicateKey?: string,
    ) => void;
}

export const StatusContext = React.createContext<StatusValue>({
    status: null,
    error: null,
    networkStateCode: "Connecting",
    hasFault: false,
    removeRapidPollCheck: () => null,
    startRapidPoll: () => null,
});

export interface StatusError {
    message: string;
    type: "TrainerOffline" | "Authentication" | "Authorization" | "Unknown";
}

export const TRAINER_OFFLINE =
    "Check to make sure the trainer is powered on and connected to the internet.";
export const REFRESH_AUTH =
    "We ran into an issue and need to restart your session.";
const REESTABLISHING_THRESHOLD = 15_000;
const DOWN_THRESHOLD = 30_000;
const POLLING_RATE = 5_000;
const TIMEOUT = 5_000;

interface IncomingCoachStatus extends Omit<CoachStatus, "timestamp"> {
    timestamp: string;
}

interface Props {
    children: React.ReactNode;
}

export type FastPollingPredicate = (status: IncomingCoachStatus) => boolean;

const RETRY_KEY = "status hook";

export const StatusProvider = React.memo(({ children }: Props) => {
    const { pathname } = useLocation();
    const {
        status: pairingStatus,
        sessionId,
        trainerId,
        pairingCancelled,
        pairingVerified,
        unpair,
    } = usePairingContext();
    const { currentUser } = useCurrentUser();
    const [status, setStatus] = React.useState<CoachStatus | null>(null);
    const [rapidPollInterval, setRapidPollInterval] = React.useState<
        null | number
    >(null);
    const [error, setError] = React.useState<StatusError | null>(null);
    const lastRequest = React.useRef(performance.now());
    const lastSuccess = React.useRef(performance.now());
    const hidden = React.useRef(false);
    const gap = lastRequest.current - lastSuccess.current;
    const hasStatus = React.useMemo(() => !!status, [status]);
    const isFetching = React.useRef(false);
    const [predicates, setPredicates] = React.useState<
        Map<string, FastPollingPredicate>
    >(new Map<string, FastPollingPredicate>());

    React.useEffect(() => {
        registerRetry(RETRY_KEY);

        return () => cancelRetry(RETRY_KEY);
    }, []);

    const networkStateCode = React.useMemo<NetworkStateCode>(() => {
        if (!hasStatus) {
            if (error?.type === "TrainerOffline") {
                return "TrainerOffline";
            }

            if (error?.type === "Authorization") {
                return "NotAuthorized";
            }

            if (gap > DOWN_THRESHOLD) {
                logger.warn(
                    'Setting network state to "ServiceConnectionDown"',
                    { gap, hasStatus },
                );
                return "ServiceConnectionDown";
            }

            return "Connecting";
        }

        if (error && error.type !== "TrainerOffline") {
            logger.warn('Setting network state to "Unknown"', {
                gap,
                hasStatus,
            });
            return "Unknown";
        }

        if (gap > DOWN_THRESHOLD) {
            if (error?.type === "TrainerOffline") {
                logger.warn('Setting network state to "TrainerOffline"', {
                    gap,
                    hasStatus,
                });
                return "TrainerConnectionDown";
            }
            logger.warn('Setting network state to "ServiceConnectionDown"', {
                gap,
                hasStatus,
            });
            return "ServiceConnectionDown";
        }

        if (gap > REESTABLISHING_THRESHOLD) {
            logger.warn('Setting network state to "ConnectionReestablishing"', {
                gap,
                hasStatus,
            });
            return "ConnectionReestablishing";
        }

        return "Connected";
    }, [hasStatus, error, gap]);

    const hasFault = React.useMemo<boolean>(() => {
        if (!status || !status.fault) {
            return false;
        }

        return !status.fault.failures.every((f) => f.source === "vision");
    }, [status]);

    const fetchStatus = React.useCallback(
        async (allowRetry = false) => {
            if (isFetching.current || hidden.current) {
                return;
            }
            isFetching.current = true;
            try {
                lastRequest.current = performance.now();
                const statusResponse =
                    await pairedFetchApi<IncomingCoachStatus>(
                        trainerId,
                        "/api/status",
                        "GET",
                        undefined,
                        TIMEOUT,
                    );

                setError(null);
                setStatus({
                    ...statusResponse,
                    timestamp: new Date(statusResponse.timestamp),
                });
                lastSuccess.current = performance.now();

                if (pairingStatus === "validating") {
                    if (statusResponse.session !== null) {
                        logger.info(
                            "Status response had a session - verifying pairing",
                        );
                        pairingVerified(
                            statusResponse.clientId,
                            statusResponse.session.id,
                        );
                    }
                }

                if (statusResponse.session === null) {
                    logger.info(
                        `Status response had no session - setting unpaired [${pathname}]`,
                    );
                    pairingCancelled();
                }

                if (statusResponse.session?.users[0].id !== currentUser?.id) {
                    logger.info(
                        "Status response user ID did not match logged in user's id",
                    );
                    pairingCancelled();
                }

                if (rapidPollInterval) {
                    if (predicates.size === 0) {
                        setRapidPollInterval(null);
                    } else {
                        const remaining: Map<string, FastPollingPredicate> =
                            new Map<string, FastPollingPredicate>();
                        predicates.forEach((p, k) => {
                            const result = p(statusResponse);
                            if (!result) {
                                remaining.set(k, p);
                            } else {
                                logger.debug(
                                    `rapid polling predicate ${k} was satisfied`,
                                );
                            }
                        });
                        setPredicates(remaining);
                        if (remaining.size === 0) {
                            logger.debug(
                                "No rapid polling predicates remain, stopping rapid polling",
                            );
                            setRapidPollInterval(null);
                        }
                    }
                }
            } catch (e) {
                logFetchError(e);
                if (e instanceof PairRequiredError) {
                    logger.warn("Attempted to fetch status without trainer ID");
                    setError({
                        message: "No trainer id available",
                        type: "Unknown",
                    });
                }
                if (e instanceof FetchError) {
                    switch (e.statusCode) {
                        case 401:
                            setError({
                                message: REFRESH_AUTH,
                                type: "Authentication",
                            });
                            break;
                        case 403:
                            logger.warn(
                                "fetchStatus: User not authorized to view session",
                            );
                            setError({
                                message:
                                    "You are not authorized to view this session",
                                type: "Authorization",
                            });
                            unpair();
                            break;
                        case 404:
                        case 504:
                            if (status?.ready === "SHUTDOWN") {
                                logger.info(
                                    "Previous status indicates SHUTDOWN - unpairing and clearing error status",
                                );
                                setError(null);
                                unpair();
                            } else if (
                                pairingStatus === "validating" &&
                                !allowRetry
                            ) {
                                logger.info(
                                    "Trainer unreachable while attempting to validate session.",
                                );
                                setError(null);
                                setStatus(null);
                                unpair();
                            } else if (!allowRetry) {
                                logger.info(
                                    "fetchStatus: Trainer offline while trying to fetch status",
                                    {
                                        responseStatus: e.statusCode,
                                    },
                                );
                                setError({
                                    message: TRAINER_OFFLINE,
                                    type: "TrainerOffline",
                                });
                            }
                            break;
                        default:
                            logger.error(
                                "fetchStatus: unexpected error fetching status from trainer",
                                {
                                    status: e.statusCode,
                                },
                            );
                            setError({
                                message: `Unexpected error fetching status from the trainer, status code ${e.statusCode}`,
                                type: "Unknown",
                            });
                            break;
                    }
                }
            } finally {
                isFetching.current = false;
            }
        },
        [
            currentUser,
            trainerId,
            pairingStatus,
            pathname,
            predicates,
            rapidPollInterval,
            status?.ready,
            pairingCancelled,
            pairingVerified,
            unpair,
        ],
    );

    // if we're paired, set error to null because being unpaired can set an
    // unauthorized error, and we want that to reset when we're paired again
    React.useEffect(() => {
        if (pairingStatus === "paired") {
            setError(null);
        }
    }, [pairingStatus]);

    // Fetch status on mount
    React.useEffect(() => {
        async function tryFetchStatus() {
            await retry(
                async () => pairedFetchApi(trainerId, "/api/status"),
                RETRY_KEY,
                1000,
                5,
            );
        }

        if (
            pairingStatus === "validating" &&
            trainerId &&
            sessionId &&
            isFetching.current === false
        ) {
            logger.info(
                `Pairing status: ${pairingStatus} and trainer id set (${trainerId}) - fetching status`,
            );
            tryFetchStatus()
                .then(() => pairingVerified(trainerId, sessionId))
                .catch(() => {
                    setError({
                        message: "Could not fetch status",
                        type: "Unknown",
                    });
                    pairingCancelled();
                });
        }
    }, [
        pairingStatus,
        sessionId,
        trainerId,
        pairingCancelled,
        pairingVerified,
    ]);

    React.useEffect(() => {
        const handleVisibilityChange = () => {
            // mark visibility state and reset timers so when we come back there
            // isn't a big gap
            logger.info("Browser visibility change", {
                hidden: document.hidden,
            });
            hidden.current = document.hidden;
            lastSuccess.current = performance.now();
            lastRequest.current = performance.now();

            if (!document.hidden) {
                if (pairingStatus === "paired") {
                    logger.info(
                        "Browser visible and user is paired, attempting to fetch",
                    );
                    checkForSession()
                        .then((session) => {
                            if (session) {
                                const { trainerId: tId, sessionId: sId } =
                                    session;
                                pairingVerified(tId, sId);
                                const allowRetry = trainerId !== tId;
                                void fetchStatus(allowRetry);
                            }
                        })
                        .catch((err) => {
                            logFetchError(
                                err,
                                "Validating status on browser visibility change",
                            );
                        });
                } else {
                    logger.info("Browser visible but no session detected");
                }
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    }, [fetchStatus, pairingVerified, pairingStatus, trainerId]);

    useInterval(
        () => {
            fetchStatus().catch(() =>
                setError({
                    message: "Could not fetch status",
                    type: "Unknown",
                }),
            );
        },
        pairingStatus !== "paired" ? null : (rapidPollInterval ?? POLLING_RATE),
    );

    React.useEffect(() => {
        if (pairingStatus === "unpaired" && status !== null) {
            logger.info("Clearing status.");
            setStatus(null);
        }
    }, [pairingStatus, status]);

    const value = React.useMemo(
        () => ({
            status,
            error,
            networkStateCode,
            hasFault,
            removeRapidPollCheck: (key: string) => {
                setPredicates((prev) => {
                    prev.delete(key);
                    return prev;
                });
            },
            startRapidPoll: (
                interval: number,
                predicate?: FastPollingPredicate,
                predicateKey?: string,
            ) => {
                logger.info(
                    `Starting rapid polling for ${predicateKey ?? "unknown"} - ${interval}ms`,
                );
                setRapidPollInterval(interval);
                if (predicate && predicateKey) {
                    setPredicates((prev) => prev.set(predicateKey, predicate));
                }
            },
        }),
        [status, error, networkStateCode, hasFault],
    );

    return (
        <StatusContext.Provider value={value}>
            <Loading
                open={
                    pairingStatus === "unknown" ||
                    (pairingStatus === "paired" &&
                        networkStateCode === "Connecting")
                }
            />
            {children}
        </StatusContext.Provider>
    );
});

StatusProvider.displayName = "StatusProvider";

export function useStatus(): StatusValue {
    return React.useContext(StatusContext);
}
