import * as React from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AppWorkoutWithSummary } from "@volley/data";
import { AppParameters } from "@volley/shared/app-models";
import {
    type PickleballGameState,
    type PickleballGameParameters,
    type PickleballGameConfig,
    type DifficultyLevel,
    PickleballGameDefaults,
    PickleballGamePlayerCount,
} from "@volley/shared/apps/pickleball-game-models";
import { JSONObject } from "@volley/shared/common-models";

import logger from "../../../../../log";
import Loading from "../../../../common/Loading";
import { useCurrentUser } from "../../../../hooks/currentUser";
import { useStatus } from "../../../../hooks/status";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import BasicLevelSelector from "../../Shared/BasicLevelSelector";
import CaptureToast from "../../Shared/CaptureToast";
import OptionSelector from "../../Shared/OptionSelector";
import PlayAppBar from "../../Shared/PlayAppBar";
import SpeedAdjustment from "../../Shared/SpeedAdjustment";
import ThrowCount from "../../Shared/ThrowCount";
import useAppWorkouts, { appWorkoutToUpdate } from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import ErrorDialog from "../4-multi-shot/play/ErrorDialog";

import InstructionDialog from "./InstructionDialog";
import Scoreboard from "./ScoreBoard";
import workoutShots from "./shots.json";

//const singlePlayerPosition = {
//   x: -0.383402489626556,
//   y: 6.8309,
//   sys: "physics",
//};
//
//const adPosition = {
//    x: -1.75,
//    y: 6.8309,
//    sys: "physics",
//};

// const defaultTrainerPosition = {
//     positionX: 0,
//     positionY: -7.51,
//     positionYaw: 0,
//     positionHeight: 54,
// };

interface DifficultyLevelActionValue {
    player: keyof PickleballGameParameters["difficultyLevels"];
    value: DifficultyLevel;
}

type ParamsAction =
    | { type: "load" }
    | { type: "init"; value: AppWorkoutWithSummary }
    | { type: "play" }
    | { type: "shots"; value: number }
    | { type: "speedAdjustment"; value?: number }
    | { type: "playerCount"; value: PickleballGamePlayerCount }
    | { type: "difficultyLevels"; value: DifficultyLevelActionValue }
    | {
          type: "player1Position";
          value: PickleballGameParameters["player1Position"];
      };

interface PickleballGameSetup {
    status: "uninitialized" | "initializing" | "initialized";
    paramsChanged: boolean;
    params: PickleballGameParameters;
    defaults: PickleballGameDefaults | null;
    workoutConfig: PickleballGameConfig | null;
    workout: AppWorkoutWithSummary | null;
}

function workoutToPlayable(
    state: PickleballGameSetup,
): AppWorkoutWithSummary | null {
    if (!state.workout && !state.workoutConfig) {
        return null;
    }
    return {
        ...state.workout,
        config: state.workoutConfig as unknown as JSONObject,
    };
}

function paramsReducer(
    state: PickleballGameSetup,
    action: ParamsAction,
): PickleballGameSetup {
    switch (action.type) {
        case "load": {
            return { ...state, status: "initializing" };
        }
        case "init": {
            const defaults = action.value
                .config as unknown as PickleballGameDefaults;
            return {
                ...state,
                status: "initialized",
                defaults,
                workoutConfig: generateWorkout(state.params, defaults),
                workout: action.value,
            };
        }
        case "play":
            return { ...state, paramsChanged: false };
        case "shots": {
            const params = { ...state.params, numberOfBalls: action.value };
            const workoutConfig = generateWorkout(params, state.defaults);
            return { ...state, params, paramsChanged: true, workoutConfig };
        }
        case "speedAdjustment": {
            const params = { ...state.params, speedAdjustment: action.value };
            const workoutConfig = generateWorkout(params, state.defaults);
            return { ...state, params, paramsChanged: true, workoutConfig };
        }
        case "playerCount": {
            const params = { ...state.params, playerCount: action.value };
            const workoutConfig = generateWorkout(params, state.defaults);
            return { ...state, params, paramsChanged: true, workoutConfig };
        }
        case "player1Position": {
            const params = { ...state.params, player1Position: action.value };
            const workoutConfig = generateWorkout(params, state.defaults);
            return { ...state, params, paramsChanged: true, workoutConfig };
        }
        case "difficultyLevels": {
            const params = {
                ...state.params,
                difficultyLevels: {
                    ...state.params.difficultyLevels,
                    [action.value.player]: action.value.value,
                },
            };
            const workoutConfig = generateWorkout(params, state.defaults);
            return { ...state, params, paramsChanged: true, workoutConfig };
        }
        default:
            return state;
    }
}

const defaultParameters: PickleballGameParameters = {
    numberOfBalls: 20,
    playerCount: 1,
    initialDelay: 4000,
    player1Position: "ad",
    difficultyLevels: {
        player1: 1,
        player2: 1,
    },
};

type KnownBallCount = "5" | "10" | "15" | "20" | "All";
const KnownBallCounts: Record<KnownBallCount, number> = {
    5: 5,
    10: 10,
    15: 15,
    20: 20,
    All: 60,
};

function mirrorShots(
    shots: PickleballGameConfig["adShots"],
): PickleballGameConfig["deuceShots"] {
    return shots.map((s) => ({
        ...s,
        pan: -s.pan,
    }));
}

function validateSavedWorkout(workout: AppWorkoutWithSummary): boolean {
    const { config } = workout;
    return Object.hasOwn(config as unknown as JSONObject, "court");
}

function generateWorkout(
    params: PickleballGameParameters,
    defaults: PickleballGameDefaults | null,
): PickleballGameConfig | null {
    const interval = 3_250;
    if (!defaults) {
        return null;
    }

    const { court } = defaults;

    if (params.playerCount === 1) {
        let configuredShots: PickleballGameConfig["adShots"] = [];
        if (params.player1Position === "ad") {
            configuredShots = court.half[params.difficultyLevels.player1 - 1]
                .shots as PickleballGameConfig["adShots"];
        } else if (params.player1Position === "deuce") {
            configuredShots = mirrorShots(
                court.half[params.difficultyLevels.player1 - 1]
                    .shots as PickleballGameConfig["adShots"],
            );
        } else {
            // full court
            configuredShots =
                court.full[params.difficultyLevels.player1 - 1].shots;
        }

        return {
            adShots: [],
            deuceShots: configuredShots,
            interval,
            playerPosition: defaults.positions[params.player1Position],
        };
    } else {
        return {
            adShots: court.half[params.difficultyLevels.player1 - 1].shots,
            deuceShots: mirrorShots(
                court.half[params.difficultyLevels.player2 - 1].shots,
            ),
            interval,
            playerPosition: defaults.positions.ad,
        };
    }
}

const ballCountMarks = Object.keys(KnownBallCounts).map((k) => ({
    label: k,
    value: KnownBallCounts[k as KnownBallCount],
}));

interface ParametersSectionProps {
    children: React.ReactNode;
    label?: string;
}

function ParametersSection({
    label = "",
    children,
}: ParametersSectionProps): JSX.Element {
    return (
        <Box component="div" sx={{ mb: 2 }}>
            {label && (
                <Typography variant="h5" mb={1}>
                    {label}
                </Typography>
            )}
            {children}
        </Box>
    );
}

export default function PickleballGame(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const idMaybe = React.useMemo(() => parseInt(id ?? "", 10), [id]);
    const { getWorkout, updateWorkout } = useAppWorkouts();

    const [state, dispatch] = React.useReducer(paramsReducer, {
        status: "uninitialized",
        params: defaultParameters,
        defaults: null,
        paramsChanged: false,
        workoutConfig: null,
        workout: null,
    });
    const [playClicked, setPlayClicked] = React.useState<boolean>(false);
    const [instructionDialogOpen, setInstructionDialogOpen] =
        React.useState<boolean>(false);
    const { status } = useStatus();
    const { isVisionStarting } = usePosition();
    const { checkForLift, stop: stopLift } = useLift();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    const { isAdmin } = useCurrentUser();

    const {
        start,
        pause,
        playState,
        playInitiated,
        workoutState,
        playDisabled,
        pauseDisabled,
        captureDisabled,
        captureVideo,
        captureStatus,
        stop: stopWorkout,
    } = useAppWorkoutPlay({
        workout: workoutToPlayable(state),
        parameters: state.params as unknown as AppParameters,
    });

    const liftTargetHeight = React.useMemo(() => {
        if (playInitiated) {
            return state.workout?.positionHeight;
        }

        return undefined;
    }, [state.workout, playInitiated]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        if (playState !== "stopped" || playInitiated) {
            await stopWorkout();
        }
    }, [playInitiated, playState, stopLift, stopWorkout]);

    const handlePlayClicked = React.useCallback(async () => {
        if (playState === "paused" && state.paramsChanged) {
            await stopWorkout();
        }
        setPlayClicked(true);
    }, [playState, state.paramsChanged, stopWorkout]);

    React.useEffect(() => {
        if (playClicked && state.workout) {
            dispatch({ type: "play" });
            setPlayClicked(false);
            checkForLift();
            void start();
        }
    }, [checkForLift, playClicked, start, state.workout]);

    const pickleWorkoutState =
        workoutState as unknown as PickleballGameState | null;
    const ballsThrown = pickleWorkoutState?.currentShotNumber ?? 0;
    const ballsToThrow = state.params.playerCount * state.params.numberOfBalls;
    const summaryText = playInitiated ? `${ballsThrown}/${ballsToThrow}` : "";
    const score = pickleWorkoutState?.score ?? { p1: 0, p2: 0 }; // TODO (cbley) - type
    const difficultyLevelsLabel =
        state.params.playerCount === 1
            ? "Difficulty Level"
            : "Difficulty Levels";

    const onChangeSpeedAdjustment = React.useCallback((value?: number) => {
        dispatch({
            type: "speedAdjustment",
            value,
        });
    }, []);

    React.useEffect(() => {
        // This is tempoary to handle migrating the full shot dictionary into
        if (idMaybe && state.status === "uninitialized") {
            dispatch({ type: "load" });
            getWorkout(idMaybe)
                .then((workout) => {
                    if (!workout) {
                        return workout;
                    }

                    if (!validateSavedWorkout(workout)) {
                        logger.info(
                            `Saved Pickleball workout is not formatted correctly - updating!`,
                        );
                        const updatedConfig: PickleballGameDefaults = {
                            court: workoutShots as unknown as PickleballGameDefaults["court"],
                            positions: {
                                full: { x: 0, y: 0 },
                                ad: { x: 0, y: 0 },
                                deuce: { x: 0, y: 0 },
                            },
                            defaultRate: 3_250,
                        };
                        workout.config = updatedConfig as unknown as JSONObject;
                        return updateWorkout(appWorkoutToUpdate(workout));
                    } else {
                        return workout;
                    }
                })
                .then((workout) => {
                    if (workout) {
                        dispatch({ type: "init", value: workout });
                    }
                });
        }
    }, [idMaybe, state.status, getWorkout, updateWorkout]);

    if (!state.defaults) {
        if (!idMaybe) {
            return (
                <ErrorDialog
                    buttonText="Go Back"
                    header="Unable to Load Workout"
                    text="We're unable to load the workout. Please go back and try again."
                    onClick={() => window.history.back()}
                />
            );
        }
        return <Loading />;
    }

    return (
        <>
            <Stack
                sx={{
                    backgroundColor: "background.default",
                    p: 2,
                    mx: -1,
                    height: "90vh",
                    pb: 16,
                }}
            >
                <Typography variant="h3" mb={1}>
                    Third Shot Drop Game (BETA)
                </Typography>
                <Box component="div" mb={2}>
                    <Button onClick={() => {}} variant="text">
                        <Typography
                            variant="h4"
                            color="info.main"
                            onClick={() => setInstructionDialogOpen(true)}
                        >
                            View Instructions
                        </Typography>
                    </Button>
                </Box>

                <Typography variant="h3" mb={2} color="primary.main">
                    Setup
                </Typography>

                <ParametersSection>
                    <OptionSelector
                        label="Player Count"
                        labelWrapperSx={{ flex: 1 }}
                        toggleButtonSx={{ flex: 2 }}
                        options={[
                            { value: 1, label: "1 Player" },
                            { value: 2, label: "2 Player" },
                        ]}
                        selected={state.params.playerCount}
                        setOption={(value) => {
                            dispatch({ type: "playerCount", value });
                        }}
                    />
                </ParametersSection>

                {state.params.playerCount === 1 && (
                    <ParametersSection>
                        <OptionSelector
                            label="Player Position"
                            labelWrapperSx={{ flex: 1 }}
                            toggleButtonSx={{ flex: 2 }}
                            options={[
                                { value: "full", label: "Full Court" },
                                { value: "ad", label: "Ad" },
                                { value: "deuce", label: "Deuce" },
                            ]}
                            selected={state.params.player1Position}
                            setOption={(value) => {
                                if (value !== null) {
                                    dispatch({
                                        type: "player1Position",
                                        value,
                                    });
                                }
                            }}
                        />
                    </ParametersSection>
                )}

                <ParametersSection label={difficultyLevelsLabel}>
                    <BasicLevelSelector
                        label={
                            state.params.playerCount === 1 ? "Level" : "Ad Side"
                        }
                        labelWrapperSx={{ flex: 1 }}
                        level={state.params.difficultyLevels.player1}
                        toggleButtonSx={{ flex: 2 }}
                        setLevel={(value) => {
                            if (value !== null) {
                                dispatch({
                                    type: "difficultyLevels",
                                    value: {
                                        player: "player1",
                                        value: value as DifficultyLevel,
                                    },
                                });
                            }
                        }}
                        wrapperSx={
                            state.params.playerCount === 2 ? { mb: 1 } : {}
                        }
                    />
                    {state.params.playerCount === 2 && (
                        <BasicLevelSelector
                            label="Deuce Side"
                            labelWrapperSx={{ flex: 1 }}
                            level={state.params.difficultyLevels.player2}
                            toggleButtonSx={{ flex: 2 }}
                            setLevel={(value) => {
                                if (value !== null) {
                                    dispatch({
                                        type: "difficultyLevels",
                                        value: {
                                            player: "player2",
                                            value: value as DifficultyLevel,
                                        },
                                    });
                                }
                            }}
                        />
                    )}
                </ParametersSection>

                <ParametersSection>
                    <ThrowCount
                        disabled={false}
                        selectedThrowCount={state.params.numberOfBalls}
                        onUserThrowCountChanged={(number) => {
                            dispatch({ type: "shots", value: number });
                        }}
                        marks={ballCountMarks}
                        min={1}
                        max={20}
                    />
                </ParametersSection>

                <ParametersSection>
                    <SpeedAdjustment
                        disabled={playState === "playing"}
                        value={state.params.speedAdjustment}
                        sport="PICKLEBALL"
                        cacheKey="pickleball-game"
                        onChange={onChangeSpeedAdjustment}
                    />
                </ParametersSection>

                <Box component="div" sx={{ mb: 8 }}>
                    <Typography variant="h3" color="primary.main">
                        Score
                    </Typography>
                    <Scoreboard
                        playerCount={state.params.playerCount}
                        adScore={score.p2}
                        deuceScore={score.p1}
                    />
                </Box>

                <PlayAppBar
                    onPauseClicked={() => pause()}
                    onPlayClicked={handlePlayClicked}
                    pauseDisabled={pauseDisabled}
                    playDisabled={visionUnavailable || playDisabled}
                    playState={playState}
                    showRecord={isAdmin()}
                    onRecordClicked={() => captureVideo()}
                    playSummary={summaryText}
                    recordDisabled={visionUnavailable || captureDisabled}
                    recordingStatus={captureStatus}
                />
                <LiftModal
                    stop={handleLiftStop}
                    targetHeight={liftTargetHeight}
                    message="The trainer is adjusting the head height"
                />
                <CaptureToast captureStatus={captureStatus} />
            </Stack>
            <InstructionDialog
                open={instructionDialogOpen}
                onClose={() => setInstructionDialogOpen(false)}
            />
        </>
    );
}
