import { PlayerPosition, PlayMode } from "./app-common-models";

export interface CuratedWorkoutParameters {
    numberOfBalls: number;
    intervalOverride: number;
    initialDelay: number;
    shuffle: boolean;
    playMode: PlayMode;
    speedAdjustment?: number;
}

export interface CuratedWorkoutState {
    currentShotNumber: number;
    countdown: number;
}

export interface CuratedWorkoutShot {
    id: string;
    name?: string;
    launchSpeed: number;
    launchSpeedVariance: number;
    tilt: number;
    tiltVariance: number;
    pan: number;
    panVariance: number;
    spinDirection: number;
    spinSpeed?: number;
    spinLevel?: number;
    intervalOverride?: number;
}

export interface CuratedWorkoutConfig {
    shots?: CuratedWorkoutShot[];
    playerPosition?: PlayerPosition;
    interval?: number;
    shotCount?: number;
    randomize?: boolean;
}

export const Levels = [1, 2, 3, 4, 5] as const;

export type LevelNumber = (typeof Levels)[number];

export interface LeveledWorkoutLevel {
    number: LevelNumber;
    interval?: number;
    shotCount?: number;
    randomize?: boolean;
    shots: CuratedWorkoutShot[];
}

export interface LeveledWorkoutConfig {
    levels: Record<LevelNumber, LeveledWorkoutLevel>;
    playerPosition?: PlayerPosition;
}
