import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AppWorkoutWithSummary } from "@volley/data";
import type { ServePosition } from "@volley/shared/apps/app-common-models";
import type {
    ServeAndVolleyParameters,
    DifficultyLevel,
    ServeAndVolleyAppConfig,
} from "@volley/shared/apps/serveandvolley-models";
import { JSONObject } from "@volley/shared/common-models";

import { logFetchError } from "../../../../../util/fetchApi";
import useDialog from "../../../../Dialog/useDialog";
import Loading from "../../../../common/Loading";
import ResizableWorkoutVisualizer from "../../../../common/Visualizer/ResizableWorkoutVisualizer";
import { VisualizerAOI } from "../../../../common/Visualizer/models";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useCurrentUser } from "../../../../hooks/currentUser";
import { useStatus } from "../../../../hooks/status";
import useIntercom from "../../../../hooks/useIntercom";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import usePrevious from "../../../../hooks/usePrevious";
import { useTrainerFeatures } from "../../../../hooks/useTrainerFeatures";
import BasicLevelSelector from "../../Shared/BasicLevelSelector";
import CaptureToast from "../../Shared/CaptureToast";
import DelaySlider from "../../Shared/DelaySlider";
import OptionSelector from "../../Shared/OptionSelector";
import PlayAppBar from "../../Shared/PlayAppBar";
import SpeedAdjustment from "../../Shared/SpeedAdjustment";
import useAppWorkouts from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import WorkoutErrorDialog from "../4-multi-shot/play/ErrorDialog";

import ErrorDialog from "./ErrorDialog";
import InstructionDialog from "./InstructionDialog";
import LocalizingDialog from "./LocalizingDialog";
import VisionSystemStartingDialog from "./VIsionSystemStartingDialog";
import WorkflowSteps from "./WorkflowSteps";

// player positions for deuce and add
const deucePosition = {
    x: 2000,
    y: -400,
    sys: "physics",
};
const adPosition = {
    x: 4100,
    y: -400,
    sys: "physics",
};

const summaryText = "summary";

function getDefaultParams(
    workout: AppWorkoutWithSummary,
    trainerFeatures: string[],
): ServeAndVolleyParameters {
    const hasPoseServeDetection =
        trainerFeatures.includes("poseServeDetection");

    const appConfig = workout.config as unknown as ServeAndVolleyAppConfig;

    return {
        shots: appConfig.shots[1],
        difficultyLevel: 1,
        shotDelayMilliseconds: hasPoseServeDetection ? 500 : 0,
        servePosition: "deuce",
        serveAOI: appConfig.serveAOI.deuce,
        serveExitAOI: appConfig.exitAOI.deuce,
        targetAOI: appConfig.targetAOI.deuce,
    };
}

type ParamsAction =
    | { type: "difficultyLevel"; value: DifficultyLevel }
    | { type: "servePosition"; value: ServePosition }
    | { type: "shotDelay"; value: number }
    | { type: "speedAdjustment"; value?: number };

function paramsReducer(
    state: ServeAndVolleyParameters,
    action: ParamsAction,
): ServeAndVolleyParameters {
    switch (action.type) {
        case "difficultyLevel":
            return {
                ...state,
                difficultyLevel: action.value,
            };
        case "servePosition": {
            return {
                ...state,
                servePosition: action.value,
            };
        }
        case "shotDelay":
            return {
                ...state,
                shotDelayMilliseconds: action.value * 1000, // convert to milliseconds
            };
        case "speedAdjustment":
            return {
                ...state,
                speedAdjustment: action.value,
            };
        default:
            return state;
    }
}

interface ServeAndVolleyProps {
    workout: AppWorkoutWithSummary;
    trainerFeatures: string[];
}

function ServeAndVolley({
    workout,
    trainerFeatures,
}: ServeAndVolleyProps): JSX.Element {
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        getDefaultParams(workout, trainerFeatures),
    );
    const [playClicked, setPlayClicked] = React.useState<boolean>(false);
    const { status } = useStatus();
    const workoutStatus = status?.workouts;

    const { position, isVisionStarting, isVisionFaulted, cancel } =
        usePosition();

    const { checkForLift, stop: stopLift } = useLift();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    const { isAdmin } = useCurrentUser();
    const [instructionDialogOpen, setInstructionDialogOpen] =
        React.useState<boolean>(false);

    // we use this ref to track if the settings have been changed and we should start a new game
    const modifiedRef = React.useRef(true);

    // we use this ref to track if a stop has been requested
    const stopRequestedRef = React.useRef(false);

    // we use this ref to force localization
    const forceLocalizationRef = React.useRef(true);

    const [localizingDialogOpen, setLocalizingDialogOpen] =
        React.useState(false);

    const [visionErrorDialogOpen, setVisionErrorDialogOpen] =
        React.useState(false);

    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

    const { setDialogType } = useDialog();

    const appConfig = workout.config as unknown as ServeAndVolleyAppConfig;

    const workoutParams = React.useMemo(() => {
        const shots = appConfig.shots[paramsState.difficultyLevel];
        const serveAOI = appConfig.serveAOI[paramsState.servePosition];
        const targetAOI = appConfig.targetAOI[paramsState.servePosition];
        const serveExitAOI = appConfig.exitAOI[paramsState.servePosition];
        const visionConfig = trainerFeatures.includes("serveAndVolleyV3")
            ? appConfig.visionConfig
            : undefined;
        return {
            ...paramsState,
            shots,
            serveAOI,
            targetAOI,
            serveExitAOI,
            visionConfig,
        };
    }, [appConfig, paramsState, trainerFeatures]);

    const workoutPosition = React.useMemo(() => {
        return appConfig.trainerPosition[paramsState.servePosition];
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    const {
        start,
        playState,
        playInitiated,
        workoutState,
        playDisabled,
        pauseDisabled,
        captureDisabled,
        captureVideo,
        captureStatus,
        stop: stopWorkout,
    } = useAppWorkoutPlay({
        workout: { ...workout, config: {}, ...workoutPosition },
        parameters: workoutParams as unknown as JSONObject,
        localizedPosition: position,
    });

    const handleStopWorkout = React.useCallback(async () => {
        stopRequestedRef.current = true;
        await stopWorkout();
    }, [stopWorkout]);

    const liftTargetHeight = React.useMemo(() => {
        if (playInitiated) {
            return workout?.positionHeight;
        }

        return undefined;
    }, [workout, playInitiated]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        if (playState !== "stopped" || playInitiated) {
            await handleStopWorkout();
        }
    }, [handleStopWorkout, playInitiated, playState, stopLift]);

    const handlePlayClicked = React.useCallback(() => {
        stopRequestedRef.current = false;
        setLocalizingDialogOpen(true);
    }, []);

    const lastPlayState = usePrevious(playState);

    React.useEffect(() => {
        if (lastPlayState === "playing" && playState === "stopped") {
            modifiedRef.current = true;
        }
    }, [lastPlayState, playState]);

    React.useEffect(() => {
        if (
            workoutStatus?.appErrors &&
            workoutStatus?.playState === "playing" &&
            !stopRequestedRef.current
        ) {
            if (!errorDialogOpen) {
                setErrorDialogOpen(true);
            }
        }
    }, [errorDialogOpen, workoutStatus?.appErrors, workoutStatus?.playState]);

    // Start the workout when the play button is clicked
    React.useEffect(() => {
        if (playClicked && workout) {
            setPlayClicked(false);
            checkForLift();
            start().catch(logFetchError);
        }
    }, [checkForLift, playClicked, start, workout]);

    // complete player serving position
    const servePosition = React.useMemo(() => {
        const pos =
            paramsState.servePosition === "deuce"
                ? { x: deucePosition.x, y: deucePosition.y }
                : { x: adPosition.x, y: adPosition.y };
        return pos;
    }, [paramsState.servePosition]);

    // serve launch AOI in visualizer format for 3D render
    const visServeAOI = React.useMemo(() => {
        const aoi = appConfig.serveAOI[paramsState.servePosition];
        const visAOI: VisualizerAOI = {
            size: [
                Math.abs(aoi.lowerRightX - aoi.upperLeftX),
                Math.abs(aoi.lowerRightY - aoi.upperLeftY),
            ],
            position: {
                x: aoi.upperLeftX + (aoi.lowerRightX - aoi.upperLeftX) / 2.0,
                y: aoi.lowerRightY + (aoi.upperLeftY - aoi.lowerRightY) / 2.0,
                z: 0.05,
            },
            color: "magenta",
            opacity: 0.6,
        };
        return visAOI;
    }, [appConfig.serveAOI, paramsState.servePosition]);

    const visExitAoi = React.useMemo(() => {
        const aoi = appConfig.exitAOI[paramsState.servePosition];
        const visAOI: VisualizerAOI = {
            size: [
                Math.abs(aoi.lowerRightX - aoi.upperLeftX),
                Math.abs(aoi.lowerRightY - aoi.upperLeftY),
            ],
            position: {
                x: aoi.upperLeftX + (aoi.lowerRightX - aoi.upperLeftX) / 2.0,
                y: aoi.lowerRightY + (aoi.upperLeftY - aoi.lowerRightY) / 2.0,
                z: 0.05,
            },
            color: "yellow",
            opacity: 0.6,
        };
        return visAOI;
    }, [appConfig, paramsState.servePosition]);

    const visShots = React.useMemo(() => {
        const shots = appConfig.shots[paramsState.difficultyLevel].map(
            (shot) => ({
                ...shot,
                pan:
                    paramsState.servePosition === "deuce"
                        ? shot.pan
                        : -shot.pan,
            }),
        );
        return shots;
    }, [
        appConfig.shots,
        paramsState.difficultyLevel,
        paramsState.servePosition,
    ]);

    const plannedTrainerPosition = React.useMemo(() => {
        const pos = appConfig.trainerPosition[paramsState.servePosition];
        return {
            x: pos.positionX,
            y: pos.positionY,
            yaw: pos.positionYaw,
            heightIn: pos.positionHeight,
        };
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    const localizationSucceeded = React.useCallback(async () => {
        if (modifiedRef.current) {
            await stopWorkout();
            setPlayClicked(true);
        } else {
            setPlayClicked(true);
        }
    }, [stopWorkout]);

    const workoutForVisualizer = React.useMemo(
        () =>
            localizingDialogOpen
                ? undefined
                : {
                      trainer: plannedTrainerPosition,
                      player: [servePosition],
                      shots: visShots,
                      AOIs: [visServeAOI, visExitAoi],
                  },
        [
            localizingDialogOpen,
            plannedTrainerPosition,
            servePosition,
            visExitAoi,
            visShots,
            visServeAOI,
        ],
    );

    const workoutForLocalizingDialog = React.useMemo(
        () => ({
            trainer: plannedTrainerPosition,
            localized: position && {
                ...position,
                heightIn: plannedTrainerPosition.heightIn,
            },
            player: [servePosition],
            shots: visShots,
            AOIs: [visServeAOI, visExitAoi],
        }),
        [
            plannedTrainerPosition,
            position,
            servePosition,
            visShots,
            visServeAOI,
            visExitAoi,
        ],
    );

    const onChangeSpeedAdjustment = React.useCallback((value?: number) => {
        paramsDispatch({
            type: "speedAdjustment",
            value,
        });
    }, []);

    if (appConfig === null) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <>
            <Stack
                spacing={0}
                sx={{
                    backgroundColor: "background.default",
                    p: 2,
                    mx: -1,
                }}
            >
                <Typography variant="h3" mb={1}>
                    Serve and Volley (BETA)
                </Typography>
                <Box
                    component="div"
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                >
                    <Button onClick={() => {}} variant="text">
                        <Typography
                            variant="h4"
                            color="info.main"
                            onClick={() => setInstructionDialogOpen(true)}
                        >
                            View Instructions
                        </Typography>
                    </Button>

                    {status && isVisionFaulted && (
                        <Button
                            variant="text"
                            startIcon={<ErrorIcon />}
                            onClick={() =>
                                setDialogType("VisionFaultServeAndVolleyDialog")
                            }
                            color="error"
                        >
                            <Typography variant="h4">
                                Camera system unavailable
                            </Typography>
                        </Button>
                    )}

                    {status && isVisionStarting && (
                        <Button
                            variant="text"
                            startIcon={<HourglassEmptyIcon />}
                            onClick={() => setVisionErrorDialogOpen(true)}
                            color="warning"
                        >
                            <Typography variant="h4">
                                Camera system still starting...
                            </Typography>
                        </Button>
                    )}
                </Box>

                <Typography variant="h3" mb={2} color="primary.main">
                    Setup
                </Typography>

                <Box sx={{ mb: 1 }}>
                    <OptionSelector
                        disabled={playState === "playing"}
                        label="Server Position"
                        labelWrapperSx={{ flex: 1 }}
                        toggleButtonSx={{ flex: 2 }}
                        options={[
                            { value: "ad", label: "Ad" },
                            { value: "deuce", label: "Deuce" },
                        ]}
                        selected={paramsState.servePosition}
                        setOption={(value) => {
                            if (value !== null) {
                                paramsDispatch({
                                    type: "servePosition",
                                    value,
                                });
                                modifiedRef.current = true;
                                // force localization if we change the serve position
                                forceLocalizationRef.current = true;
                            }
                        }}
                    />
                </Box>

                <Box sx={{ mb: 2 }}>
                    <BasicLevelSelector
                        disabled={playState === "playing"}
                        label="Difficulty Level"
                        labelWrapperSx={{ flex: 1 }}
                        level={paramsState.difficultyLevel}
                        toggleButtonSx={{ flex: 2 }}
                        setLevel={(value) => {
                            if (value !== null) {
                                paramsDispatch({
                                    type: "difficultyLevel",
                                    value: value as DifficultyLevel,
                                });
                                modifiedRef.current = true;
                            }
                        }}
                    />
                </Box>

                {(!status ||
                    trainerFeatures.includes("serveAndVolleyShotDelay")) && (
                    <Box sx={{ mb: 1 }}>
                        <DelaySlider
                            disabled={playState === "playing"}
                            label="Return Delay"
                            labelWrapperSx={{ flex: 1 }}
                            sliderWrapperSx={{ flex: 2 }}
                            selectedDelay={
                                workoutParams.shotDelayMilliseconds / 1000
                            }
                            onDelayChanged={(value) => {
                                paramsDispatch({ type: "shotDelay", value });
                                modifiedRef.current = true;
                            }}
                        />
                    </Box>
                )}

                <Box mb={2}>
                    <SpeedAdjustment
                        disabled={playState === "playing"}
                        value={paramsState.speedAdjustment}
                        sport="PLATFORM_TENNIS"
                        cacheKey="serve-and-volley"
                        onChange={onChangeSpeedAdjustment}
                    />
                </Box>

                <Box component="div" mb={2}>
                    <WorkflowSteps
                        currentState={
                            (workoutState?.currentState as string) ?? ""
                        }
                    />
                </Box>

                <Box
                    component="div"
                    sx={{
                        paddingBottom: "80px",
                    }}
                >
                    <ResizableWorkoutVisualizer
                        workout={workoutForVisualizer}
                        positionProximity="Unavailable"
                        maxHeight={225}
                    />
                </Box>
            </Stack>

            <LocalizingDialog
                dialogOpen={localizingDialogOpen}
                onLocalized={(result) => {
                    setLocalizingDialogOpen(false);
                    if (result === "good") {
                        forceLocalizationRef.current = false;
                        void localizationSucceeded();
                    } else {
                        forceLocalizationRef.current = true;
                    }
                }}
                onCanceled={() => {
                    cancel();
                    setLocalizingDialogOpen(false);
                }}
                plannedPosition={plannedTrainerPosition}
                force={forceLocalizationRef.current}
                workout={workoutForLocalizingDialog}
            />

            <PlayAppBar
                onPauseClicked={handleStopWorkout}
                onPlayClicked={handlePlayClicked}
                pauseDisabled={pauseDisabled}
                playDisabled={visionUnavailable || playDisabled}
                playState={playState}
                showRecord={isAdmin()}
                onRecordClicked={() => captureVideo()}
                playSummary={summaryText}
                recordDisabled={visionUnavailable || captureDisabled}
                recordingStatus={captureStatus}
            />
            <CaptureToast captureStatus={captureStatus} />
            <LiftModal
                stop={handleLiftStop}
                targetHeight={liftTargetHeight}
                message="The trainer is adjusting the head height"
            />

            <InstructionDialog
                instructionDialogOpen={instructionDialogOpen}
                setInstructionDialogOpen={setInstructionDialogOpen}
            />

            <VisionSystemStartingDialog
                open={visionErrorDialogOpen}
                setDialogOpen={setVisionErrorDialogOpen}
            />

            <ErrorDialog
                errorDialogOpen={errorDialogOpen}
                workoutStatus={workoutStatus}
                setErrorDialogOpen={async () => {
                    await handleStopWorkout();
                    setErrorDialogOpen(false);
                }}
                visualizerComponent={
                    <ResizableWorkoutVisualizer
                        workout={{
                            trainer: plannedTrainerPosition,
                            player: [servePosition],
                            shots: [],
                            AOIs: [visServeAOI, visExitAoi],
                        }}
                        positionProximity="Good"
                    />
                }
            />
        </>
    );
}

export default function ServeAndVolleyRoot(): JSX.Element {
    const [workout, setWorkout] = React.useState<AppWorkoutWithSummary | null>(
        null,
    );
    const [error, setError] = React.useState<string | null>(null);

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const idMaybe = React.useMemo(() => parseInt(id ?? "", 10), [id]);

    const { getWorkout, addWorkout } = useAppWorkouts();
    const { physicsModelName } = usePhysicsModelContext();
    const trainerFeatures = useTrainerFeatures();
    const { status } = useStatus();
    const intercom = useIntercom();

    React.useEffect(() => {
        if (!idMaybe) {
            setError("No workout id provided");
        } else {
            getWorkout(idMaybe)
                .then((result) => {
                    if (result) {
                        setWorkout(result);
                    } else {
                        setError("Failed to load serve and volley config");
                    }
                })
                .catch((e) =>
                    logFetchError(e, "Failed to load serve and volley config"),
                );
        }
    }, [getWorkout, addWorkout, idMaybe, physicsModelName]);

    if (error) {
        return (
            <WorkoutErrorDialog
                buttonText="Back to Workouts"
                header="Workout not Found"
                text={error}
                onClick={() => {
                    navigate("/");
                }}
            />
        );
    }

    if (!workout) {
        return <Loading />;
    }

    if (status && !trainerFeatures.includes("serveAndVolley")) {
        return (
            <Box
                component="div"
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    mt: 5,
                    alignItems: "center",
                    backgroundColor: "background.default",
                }}
            >
                <Typography variant="h3" mb={4}>
                    Trainer update required.
                </Typography>
                <Typography px={2} mb={4}>
                    To play the Serve and Volley app, your trainer needs to be
                    updated. Please press the icon below to message support, and
                    we will update it for you.
                </Typography>
                <IconButton
                    onClick={() => {
                        intercom.newMessage();
                    }}
                    color="primary"
                    size="large"
                >
                    <HelpIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <ServeAndVolley workout={workout} trainerFeatures={trainerFeatures} />
    );
}
