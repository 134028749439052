import * as React from "react";

import { AOI } from "@volley/shared/dist/apps/app-common-models";

import { StyleOptions } from "../../../../Admin/Clips/canvasDrawing";
import {
    drawAOI,
    drawPlatformCourt,
    drawBallForPlatformTennis,
} from "../../../../Admin/Clips/primitiveDrawing";

export interface Serve {
    position: { x: number; y: number };
    inAoi: boolean;
    inServiceBox: boolean;
}

interface CourtProps {
    serves: Serve[];
    activeServiceBox?: AOI;
    targetAOIs: AOI[];
}

function styleForServe(serve: Serve): StyleOptions {
    const base: StyleOptions = {
        strokeStyle: "white",
        lineWidth: 2,
        fillStyle: "white",
    };

    // color red if not in AOI or service box, green if in AOI, yellow if in service box
    let color = "red";
    if (serve.inAoi) {
        color = "green";
    } else if (serve.inServiceBox) {
        color = "yellow";
    }

    return {
        ...base,
        strokeStyle: color,
        fillStyle: color,
    };
}

export default function Court({
    serves,
    activeServiceBox,
    targetAOIs,
}: CourtProps) {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Clear the entire canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Redraw the court
        drawPlatformCourt(ctx);

        // Draw all serves
        serves.forEach((result) => {
            drawBallForPlatformTennis(
                ctx,
                { ...result.position, z: 0 },
                styleForServe(result),
            );
        });

        // if we have an active service box, draw it
        if (activeServiceBox) {
            drawAOI(ctx, activeServiceBox, {
                strokeStyle: "yellow",
                lineWidth: 1,
                fillStyle: "rgba(255, 255, 0, 0.05)",
            });
        }

        // Draw all AOIs
        targetAOIs.forEach((aoi) => {
            drawAOI(ctx, aoi, {
                strokeStyle: "green",
                lineWidth: 2,
                fillStyle: "rgba(0, 255, 0, 0.10)",
            });
        });
    }, [activeServiceBox, serves, targetAOIs]);

    return <canvas ref={canvasRef} width={300} height={400} />;
}
